import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useCustomQuery = (url) => {
  // État pour les données
  const [data, setData] = useState(null);
  // État pour savoir si la requête est en cours
  const [isLoading, setIsLoading] = useState(true);
  // État pour les erreurs potentielles
  const [error, setError] = useState(null);

  // Fonction de fetch des données
  const fetchData = useCallback(async () => {
    setIsLoading(true); // Démarre le chargement
    setError(null); // Réinitialise les erreurs

    try {
      const result = await axios.get(url);
      //   if (!result.ok) {
      //     throw new Error("Erreur lors du chargement des données");
      //   }
      setData(result); // Met à jour les données
    } catch (err) {
      console.log(err);

      setError(err.message); // Enregistre l'erreur
    } finally {
      setIsLoading(false); // Termine le chargement
    }
  }, [url]);

  // Appelle la fonction de fetch lorsque le hook est utilisé
  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Retourne l'état de chargement, les données, et la fonction pour re-fetcher
  return { data, isLoading, error, refetch: fetchData };
};

export default useCustomQuery;
