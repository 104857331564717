import React from "react";
import useCustomQuery from "../Hooks/query";
import { SectionLoader } from "../uikits/others";
import { withAuth } from "../hocs/withAuth";
import { PageTitle } from "../GlobalComponents/other";
import axios from "axios";
import moment from "moment";

function Reference() {
  const { isLoading, data, refetch } = useCustomQuery("/admin/referral/all");

  function deleteReference(id) {
    axios
      .delete("/admin/referral/" + id)
      .then((res) => null)
      .catch((err) => null)
      .finally(() => refetch());
  }
  if (isLoading) {
    return <SectionLoader />;
  }
  return (
    <div className="reference">
      <PageTitle title={"Liste des References"} />
      <div className="flex f-wrap r-list">
        {data.data.map((item, i) => (
          <section key={"ref nb" + i}>
            <p>
              <span>Nom du référent :</span> <b>{item.referrorName}</b>
            </p>
            <p>
              <span>Email du référent :</span>{" "}
              <a href={"mailto:" + item.referrorEmail}>{item.referrorEmail}</a>
            </p>
            <p>
              <span>Nom du référé :</span> <b>{item.referredName}</b>
            </p>
            <p>
              <span>Email du référé :</span>{" "}
              <a href={"mailto:" + item.referredEmail}>{item.referredEmail}</a>
            </p>
            <p>
              <span>Téléphone du référé :</span> <b>{item.referredPhone}</b>
            </p>
            <p>
              <span>Date de la référence :</span>{" "}
              <b>{moment(item.createdAt).format("DD MMMM YYYY, h:mm:ss")}</b>
            </p>

            <button onClick={() => deleteReference(item._id)}>Supprimer</button>
          </section>
        ))}
      </div>
    </div>
  );
}

export default withAuth(Reference);
